/* react */
import { BrowserRouter as Switch, Routes, Route, Navigate } from 'react-router-dom'
/* utils */
import { PATH_INFORMATION_POLITY, PATH_INFORMATION_TERM, PATH_LANDING_ABOUT, PATH_LANDING_CONTACT, PATH_LANDING_HOME, randomKey } from '@utils'
/* views */
import { ContactView, HomeView, PrivacyPolicyView, TermsConditionsView, WhitepaperView } from './views'
import { AnimatePresence } from 'framer-motion'
import { Suspense } from 'react'

const AppRoutes = () => {
    const routes = [
        { path: '/', component: <HomeView /> },
        { path: PATH_LANDING_HOME, component: <HomeView /> },
        { path: PATH_LANDING_ABOUT, component: <WhitepaperView /> },
        { path: PATH_LANDING_CONTACT, component: <ContactView /> },
        { path: PATH_INFORMATION_TERM, component: <TermsConditionsView /> },
        { path: PATH_INFORMATION_POLITY, component: <PrivacyPolicyView />  },
    ]

    return (
        <>
            <Switch>
                <Suspense fallback={<span> </span>}>
                    <AnimatePresence>
                        <Routes>
                            {routes.map(({ path, component }) =>
                                path === '/' ? (
                                    <Route {...randomKey()} path={path} element={<Navigate to={PATH_LANDING_HOME} replace />} />
                                ) : (
                                    <Route {...randomKey()} path={path} element={component} />
                                )
                            )}

                            <Route path="/login" element={<Navigate to={PATH_LANDING_HOME} replace />} />
                        </Routes>
                    </AnimatePresence>
                </Suspense>
            </Switch>
        </>
    )
}

export default AppRoutes
