import { PATH_GAMES, PATH_INFORMATION_POLITY, PATH_INFORMATION_TERM, PATH_LANDING_HOME, PATH_MARKETPLACE } from '@utils'
import { ConsoleIcon, HomeIcon, MarketplaceIcon } from '@assets'

export const navItems = [
    { path: PATH_LANDING_HOME, title: 'home', icon: <HomeIcon />, isTarget: false },
    { path: PATH_MARKETPLACE, title: 'marketplace', icon: <MarketplaceIcon />, isTarget: true },
]

export const navGames = [
    { path: PATH_GAMES, title: 'casinuu_web', icon: <ConsoleIcon />, isTarget: true },
    // { path: PATH_LOGIN, title: 'game', icon: <IoGameController size={20} />, isTarget: false },
]

export const menuItemLegal = [
    { path: PATH_INFORMATION_POLITY, title: 'policy' },
    { path: PATH_INFORMATION_TERM, title: 'terms' },
    // { path: PATH_INFORMATION_GAME, title: 'game' },
]

// { path: PATH_GAMES, title: 'casinuu_web', icon: <ConsoleIcon />, isTarget: true },
// { path: PATH_LOGIN, title: 'game', icon: <IoGameController size={20} />, isTarget: true },
// { path: '/about', title: 'about', icon: <InformationIcon />, isTarget: false },
// { path: '/contact', title: 'contact', icon: <MessageIcon />, isTarget: false },
