/* react */
import { FC, memo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
/* custom hook */
import useColorScheme from '@hooks/useColorScheme.hook'
/* hooks */
import { useTranslation } from 'react-i18next'
/* layouts */
import { ModalLayout } from '@layouts'
/* utils */
import { classNames, PATH_LOGIN, randomKey } from '@utils'
/* types */
import { ColorScheme, MODAL_POSITION, navItems, ROLE_BUTTON_MAP, VARIANT_BUTTON_MAP } from '@types'
/* components */
import { Button, NavLink } from '..'
import { Switch } from 'antd'
/* assets */
import { EnglishIcon, InformationIcon, LogoTipo, MessageIcon, SettingIcon, SpanishIcon, ThemeIcon } from '@assets'
import { MdClose, MdKeyboardArrowDown } from 'react-icons/md'
import { HiOutlineArrowRight } from 'react-icons/hi'
/* styles */
import './index.scss'

const MobileSidebar: FC<{ isModal: boolean; hideModal: () => void }> = ({ isModal, hideModal, ...rest }) => {
    const [t, i18n] = useTranslation('global')

    const lang = localStorage.getItem('language')!

    const language = localStorage.getItem('language')!

    const dropdownRef = useRef<HTMLDivElement>(null)

    const navigate = useNavigate()

    const [isDrop, setIsDrop] = useState(false)

    const { setColorScheme } = useColorScheme()

    const colorScheme = sessionStorage.getItem('color_scheme')!

    const urlLogin = `${PATH_LOGIN}?lang=${lang}`

    const onChange = (checked: boolean) => {
        !checked ? setColorScheme(ColorScheme.LIGHT) : setColorScheme(ColorScheme.DARK)
        setTimeout(() => hideModal(), 200)
    }

    const handleDropDownFocus = (state: boolean) => setIsDrop(!state)

    const handleClickOutsideDropdown = (e: any) => {
        if (isDrop && !dropdownRef.current?.contains(e.target as Node)) {
            setIsDrop(false)
        }
    }

    window.addEventListener('click', handleClickOutsideDropdown)

    return (
        <ModalLayout
            isModal={isModal}
            onClose={hideModal}
            position={MODAL_POSITION.TOP}
            className={classNames('mobile-sidebar', isModal == false ? 'animate__animated animate__fadeOut animate__slower' : '')}
        >
            <div className="mobile-sidebar__main" {...rest} style={{ overflowY: 'auto' }}>
                <header className="mobile-sidebar__title">
                    <div className="mobile-sidebar__logo">
                        <i>
                            <LogoTipo />
                        </i>
                    </div>

                    <Button
                        className={classNames('mobile-sidebar__close', 'animate__animated animate__fadeIn animate__faster')}
                        role={ROLE_BUTTON_MAP.INFO}
                        variant={VARIANT_BUTTON_MAP.TEXT}
                        onClick={hideModal}
                    >
                        <i>
                            <MdClose />
                        </i>
                    </Button>
                </header>

                <nav>
                    <ul className="mobile-sidebar__options">
                        {navItems.map(({ title, path, icon, isTarget }) => (
                            <li {...randomKey()}>
                                <NavLink
                                    to={title == 'marketplace' || title == 'games' ? path.concat(`?lang=${lang}`) : path}
                                    className="mobile-sidebar__option"
                                    activeClassName="mobile-sidebar__option--active"
                                    isTarget={isTarget}
                                    onClick={title === 'game' ? () => localStorage.setItem('locationGames', JSON.stringify(true)) : () => {}}
                                >
                                    <i>{icon}</i>

                                    <span>{t(`navbar.${title}`)}</span>

                                    {title === 'game' && <span className="mobile-sidebar__option__beta-badge">Beta</span>}
                                </NavLink>
                            </li>
                        ))}

                        <li>
                            <NavLink to={'/about'} className="mobile-sidebar__option" activeClassName="mobile-sidebar__option--active">
                                <i>
                                    <InformationIcon />
                                </i>

                                <span>{t('navbar.about')}</span>
                            </NavLink>
                        </li>

                        <li>
                            <NavLink to={'/contact'} className="mobile-sidebar__option" activeClassName="mobile-sidebar__option--active">
                                <i>
                                    <MessageIcon />
                                </i>

                                <span>{t('navbar.contact')}</span>
                            </NavLink>
                        </li>

                        <li>
                            <div ref={dropdownRef}>
                                <div className="mobile-sidebar__profile" onClick={() => handleDropDownFocus(isDrop)}>
                                    <div className="mobile-sidebar__option">
                                        <i>
                                            <SettingIcon />
                                        </i>

                                        <span>{t('mobile-navbar.settings')}</span>
                                    </div>

                                    <i
                                        className={classNames(
                                            'mobile-sidebar__profile__arrow',
                                            isDrop ? 'mobile-sidebar__profile__arrow--rotate' : ''
                                        )}
                                    >
                                        <MdKeyboardArrowDown size={32} />
                                    </i>
                                </div>

                                {isDrop && (
                                    <nav>
                                        <ul className="mobile-sidebar__profile__options">
                                            {/* <li>
                                                <NavLink
                                                    to="/information"
                                                    className="mobile-sidebar__profile__option"
                                                    activeClassName="mobile-sidebar__profile__option--active"
                                                >
                                                    <i>
                                                        <DocumentIcon />
                                                    </i>

                                                    <span>{t('mobile-navbar.information')}</span>
                                                </NavLink>
                                            </li> */}

                                            <li>
                                                <div className="mobile-sidebar__profile__option">
                                                    <i>
                                                        <ThemeIcon />
                                                    </i>

                                                    <span>Dark Mode</span>

                                                    <Switch
                                                        onChange={onChange}
                                                        className="mobile-sidebar__profile__option__toggle"
                                                        defaultChecked={colorScheme === '"DARK"' ? true : false}
                                                    />
                                                </div>
                                            </li>

                                            {/* <li>
                                                <div className="mobile-sidebar__option">
                                                    <i>
                                                        <SupportIcon />
                                                    </i>

                                                    <span>{t('mobile-navbar.support')}</span>
                                                </div>
                                            </li> */}

                                            <li>
                                                <div className="mobile-sidebar__profile__language">
                                                    <span>{t('navbar.language')}</span>

                                                    <div
                                                        className={classNames(
                                                            'mobile-sidebar__option',
                                                            language == 'es' ? 'mobile-sidebar__option__language--active' : '',
                                                            'mobile-sidebar__option--hover'
                                                        )}
                                                        onClick={() => {
                                                            i18n.changeLanguage('es').then(() => {
                                                                window.location.reload()
                                                                localStorage.setItem('language', 'es')
                                                            })
                                                            setTimeout(() => hideModal(), 200)
                                                        }}
                                                    >
                                                        <i>
                                                            <SpanishIcon />
                                                        </i>

                                                        <span>{t('navbar.spanish')}</span>
                                                    </div>

                                                    <div
                                                        className={classNames(
                                                            'mobile-sidebar__option',
                                                            language == 'en' ? 'mobile-sidebar__option__language--active' : '',
                                                            'mobile-sidebar__option--hover'
                                                        )}
                                                        onClick={() => {
                                                            i18n.changeLanguage('en').then(() => {
                                                                window.location.reload()
                                                                localStorage.setItem('language', 'en')
                                                            })
                                                            setTimeout(() => hideModal(), 200)
                                                        }}
                                                    >
                                                        <i>
                                                            <EnglishIcon />
                                                        </i>

                                                        <span>{t('navbar.english')}</span>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </nav>
                                )}
                            </div>
                        </li>

                        <li>
                            <a href={urlLogin} style={{ textDecoration: 'none' }}>
                                <Button
                                    className="mobile-sidebar__login"
                                    onClick={() => navigate('/login')}
                                    role={ROLE_BUTTON_MAP.SECONDARY}
                                    variant={VARIANT_BUTTON_MAP.FILL}
                                >
                                    {t('navbar.sign-in')}
                                    <i>
                                        <HiOutlineArrowRight />
                                    </i>
                                </Button>
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
        </ModalLayout>
    )
}

export default memo(MobileSidebar)
