export const BASE_URL_SERVER = `${process.env.REACT_APP_URL}api/v1/`

export const API_KEY = '6LcEgcEnAAAAAL98a794POixWAjtZCK1H419V_0M'

export const PATH_GAMES_3D = 'http://game.casinuu.com'

export const PATH_LANDING_HOME = `landing/home`

export const PATH_LANDING_ABOUT = `landing/about`

export const PATH_LANDING_CONTACT = `landing/contact`

export const PATH_INFORMATION_TERM = 'landing/terms-and-conditions'

export const PATH_INFORMATION_POLITY = 'landing/privacy-policy'

export const PATH_MARKETPLACE = `https://${window.location.host}/app/marketplace`

export const PATH_GAMES = `https://${window.location.host}/app/games`

export const PATH_LOGIN = `https://${window.location.host}/app/login`
