/* react */
import { FC, memo } from 'react'
/* custom hook */
import useColorScheme from '@hooks/useColorScheme.hook'
/* hooks */
import { useTranslation } from 'react-i18next'
/* utils */
import { classNames, PATH_LANDING_ABOUT, PATH_LANDING_CONTACT } from '@utils'
/* types */
import { ColorScheme } from '@types'
/* components */
import { NavLink } from '@components'
import { Switch } from 'antd'
/* assets */
import { EnglishIcon, InformationIcon, MessageIcon, SpanishIcon, ThemeIcon } from '@assets'
/* styles */
import './index.scss'

const DropDownProfile: FC<{ onClick?: any }> = ({ onClick }) => {
    const [t, i18n] = useTranslation('global')

    const { setColorScheme } = useColorScheme()

    const colorScheme = sessionStorage.getItem('color_scheme')!

    const language = localStorage.getItem('language')!

    const onChange = (checked: boolean) => {
        !checked ? setColorScheme(ColorScheme.LIGHT) : setColorScheme(ColorScheme.DARK)
        setTimeout(() => onClick(), 200)
    }

    return (
        <div className="drop-down-profile">
            <ul className="drop-down-profile__options">
                <li>
                    <NavLink to={`/${PATH_LANDING_ABOUT}`} className="drop-down-profile__option" activeClassName="drop-down-profile__option--active">
                        <i>
                            <InformationIcon />
                        </i>

                        <span>{t('navbar.about')}</span>
                    </NavLink>
                </li>

                <li>
                    <NavLink
                        to={`/${PATH_LANDING_CONTACT}`}
                        className="drop-down-profile__option"
                        activeClassName="drop-down-profile__option--active"
                    >
                        <i>
                            <MessageIcon />
                        </i>

                        <span>{t('navbar.contact')}</span>
                    </NavLink>
                </li>

                <li>
                    <div className="drop-down-profile__option">
                        <i>
                            <ThemeIcon />
                        </i>

                        <span>Dark Mode</span>

                        <Switch
                            onChange={onChange}
                            className="drop-down-profile__option__toggle"
                            defaultChecked={colorScheme === '"DARK"' ? true : false}
                        />
                    </div>
                </li>

                <li>
                    <div className="drop-down-profile__option">
                        <div className="drop-down-profile__option__language">
                            <span>{t('navbar.language')}</span>

                            <div
                                className={classNames(
                                    'drop-down-profile__option',
                                    language == 'es' ? 'drop-down-profile__option__language--active' : '',
                                    'drop-down-profile__option--hover'
                                )}
                                onClick={() => {
                                    i18n.changeLanguage('es').then(() => {
                                        window.location.reload()
                                        localStorage.setItem('language', 'es')
                                    })
                                    setTimeout(() => onClick(), 200)
                                }}
                            >
                                <i>
                                    <SpanishIcon />
                                </i>

                                <span>{t('navbar.spanish')}</span>
                            </div>

                            <div
                                className={classNames(
                                    'drop-down-profile__option',
                                    language == 'en' ? 'drop-down-profile__option__language--active' : '',
                                    'drop-down-profile__option--hover'
                                )}
                                onClick={() => {
                                    i18n.changeLanguage('en').then(() => {
                                        window.location.reload()
                                        localStorage.setItem('language', 'en')
                                    })
                                    setTimeout(() => onClick(), 200)
                                }}
                            >
                                <i>
                                    <EnglishIcon />
                                </i>

                                <span>{t('navbar.english')}</span>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    )
}

export default memo(DropDownProfile)
