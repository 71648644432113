/* react */
import { FC, memo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
/* utils */
import { classNames, PATH_INFORMATION_POLITY, PATH_INFORMATION_TERM } from '@utils'
/* types */
import { navItems } from '@types'
/* componentes */
import { NavLink } from '@components'
/* assets */
import { ItechLogo, LogoTipo } from '@assets'
import { FaFacebookF, FaInstagram, FaRedditAlien } from 'react-icons/fa'
import { FaSquareXTwitter } from 'react-icons/fa6'
import { RiGitRepositoryPrivateFill } from 'react-icons/ri'
import { HiMiniClipboardDocumentList } from 'react-icons/hi2'
import { TbRating18Plus } from 'react-icons/tb'
/* styles */
import './index.scss'

const Footer: FC<{ isBackground?: boolean }> = ({ isBackground = false }) => {
    const [t] = useTranslation('global')

    const lang = localStorage.getItem('language')!

    const partners = [{ icon: <ItechLogo /> }, { icon: <TbRating18Plus  size={64}/> }]

    useEffect(() => {
        ;(window as any)?.anj_6b32b103_7f72_4d9d_8b23_0c8e27451f21?.init()
    }, [])

    return (
        <div className={classNames('footer', isBackground ? 'footer--background' : '')}>
            <section>
                <div className="footer__content">
                    <div className="footer__logo">
                        <i>
                            <LogoTipo />
                        </i>

                        <div className="footer__title">
                            <div className="footer__title__container">
                                <span>{t('footer.title')}</span>

                                <span>{t('footer.subtitle')}</span>
                            </div>

                            <div className="footer__title__contents">
                                <div
                                    id="anj-6b32b103-7f72-4d9d-8b23-0c8e27451f21"
                                    data-anj-seal-id="6b32b103-7f72-4d9d-8b230c8e27451f21"
                                    data-anj-image-size="50"
                                    data-anj-image-type="basic-small"
                                ></div>

                                {partners.map(({ icon }, index) => (
                                    <i key={index}>{icon}</i>
                                ))}
                            </div>
                        </div>
                    </div>

                    <div className="footer__contact">
                        <div>
                            <h2>{t('footer.menu')}</h2>

                            <nav>
                                <ul className="footer__options">
                                    {navItems.map(({ title, path, icon }, index) => (
                                        <li key={index + '/'}>
                                            <NavLink
                                                to={title === 'marketplace' || title === 'games' ? `/${path}?lang=${lang}` : `/${path}`}
                                                className="footer__option"
                                                activeClassName="footer__option--active"
                                                isTarget={title !== 'marketplace' ? true : false}
                                            >
                                                <i>{icon}</i>

                                                <span>{t(`navbar.${title}`)}</span>
                                            </NavLink>
                                        </li>
                                    ))}

                                    <li style={{ zIndex: 1 }}>
                                        <NavLink
                                            to={PATH_INFORMATION_POLITY}
                                            className="footer__option"
                                            activeClassName="footer__option--active"
                                            isTarget={true}
                                        >
                                            <i>
                                                <RiGitRepositoryPrivateFill />
                                            </i>

                                            <span>{t(`submenu.policy`)}</span>
                                        </NavLink>
                                    </li>

                                    <li style={{ zIndex: 1 }}>
                                        <NavLink
                                            to={PATH_INFORMATION_TERM}
                                            className="footer__option"
                                            activeClassName="footer__option--active"
                                            isTarget={true}
                                        >
                                            <i>
                                                <HiMiniClipboardDocumentList />
                                            </i>

                                            <span>{t(`submenu.terms`)}</span>
                                        </NavLink>
                                    </li>
                                </ul>
                            </nav>
                        </div>

                        <div>
                            <h2>{t('footer.contact')}</h2>

                            <a href="mailto:info@casinuu.com">info@casinuu.com</a>
                        </div>
                    </div>
                </div>

                <div className="footer__container">
                    <span>© 2024. Casinuu</span>

                    <div>
                        <a href="https://x.com/CasinuuOfficial" target="_blank">
                            <i>
                                <FaSquareXTwitter />
                            </i>
                        </a>

                        <a href="https://www.facebook.com/Casinuu.official/" target="_blank">
                            {' '}
                            <i>
                                <FaFacebookF />
                            </i>
                        </a>

                        <a href="https://www.reddit.com/user/Casinuu" target="_blank">
                            <i>
                                <FaRedditAlien />
                            </i>
                        </a>

                        <a href="https://www.instagram.com/casinuu/" target="_blank">
                            <i>
                                <FaInstagram />
                            </i>
                        </a>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default memo(Footer)
